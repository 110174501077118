import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../footer';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ScrollContainer from 'react-indiana-drag-scroll';
import { deviceDetect } from 'react-device-detect';

function Home(props) {
    const initialState = 0;
    const [btnGroupChildIndex, setBtnGroupChildIndex] = useState(initialState);
    const [isSliding, setIsSliding] = useState(false);
    const [isFullScreen, setFullScreen] = useState(false);

    const handleClick = (e) => {
        if(e.target){
            let elements = document.getElementsByClassName("button-group-child");
            for (let item of elements) {
                item.classList.remove("button-selected");
            }
            e.target.classList.add("button-selected");
            setBtnGroupChildIndex(parseInt(e.target.getAttribute("data-key")))
        }
    };

    const isPwa = () => {
        return ["fullscreen", "standalone", "minimal-ui"].some(
            (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
        );
    }

    useEffect(() => {
        if (isPwa()) {
            setFullScreen(true);
        }
    });
  
    return (
        <React.Fragment>
            <div className="homepage-container">
            <ScrollContainer id="homepage-content" className="homepage-content" horizontal={false}>
            <div className="homepage-header">
               <img id="image" src="./images/banner1.png" alt="" width="100%" height="auto"  />
            </div>
            <div className="homepage-body">
                <span  class="demo-room-header"> 5G DEMO ROOM</span>
                <Row>
                    <Col>
                        <ScrollContainer className="horizontal-scroll" horizontal={true}>
                            <Link className="item" key="1" to={isSliding ? '#' : {pathname: '/detail', state: { id: 0 } }}> 
                                <img id="0" width="100%" height="auto" src="./images/sm-thumb1.png" alt=""/>
                                <div className="caption"><span className="label"></span></div>
                            </Link>
                            <Link className="item" key="2" to={isSliding ? '#' : {pathname: '/detail', state: { id: 1 } }}> 
                                <img id="1"  width="100%" height="auto" src="./images/sm-thumb2.png" alt=""/>
                                <div className="caption">
                                    <span className="label"></span>
                                </div>
                            </Link>
                            <Link className="item" key="3"  to={isSliding ? '#' : {pathname: '/detail', state: { id: 2 } }}> 
                                <img id="2"  width="100%" height="auto" src="./images/sm-thumb3.png" alt=""/>
                                <div className="caption"><span className="label"></span></div>
                            </Link>
                            <Link className="item" key="4"  to={isSliding ? '#' : {pathname: '/detail', state: { id: 3 } }}> 
                                <img id="3"  width="100%" height="auto" src="./images/sm-thumb4-1.png" alt=""/>
                                <div className="caption"><span className="label"></span></div>
                            </Link>
                            <Link className="item" key="5"  to={isSliding ? '#' : {pathname: '/detail', state: { id: 4 } }}> 
                                <img id="4"  width="100%" height="auto" src="./images/sm-thumb5.png" alt=""/>
                                <div className="caption"><span className="label"></span></div>
                            </Link>
                            <Link className="item" key="6"  to={isSliding ? '#' : {pathname: '/detail', state: { id: 5 } }}> 
                                <img id="5"  width="100%" height="auto" src="./images/sm-thumb6.png" alt=""/>
                                <div className="caption"  style={{bottom : "60px"}}><span className="label"></span></div>
                            </Link>
                            <Link className="item" key="7"  to={isSliding ? '#' : {pathname: '/detail', state: { id: 6 } }}> 
                                <img id="6"  width="100%" height="auto" src="./images/sm-thumb7.png" alt=""/>
                                <div className="caption"><span className="label"></span></div>
                            </Link>
                        </ScrollContainer>
                    </Col>
                </Row>
                <Row>
                    <Col>
                         <ButtonGroup aria-label="Basic example" className="homepage-buttongroup">
                            <Button variant="default" data-key="0" className="button-group-child button-selected" onClick={handleClick}>Features</Button>
                            <Button variant="default" data-key="1" className="button-group-child" onClick={handleClick}>Coverage</Button>
                            <Button variant="default" data-key="2" className="button-group-child"  onClick={handleClick}>Price Plan</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                {
                btnGroupChildIndex === 0 ?
                <React.Fragment>
                    <Row >
                        <Col>
                            <div className="homepage-features homepage-features-margin-top">
                                <Row>
                                    <Col xs="4" md="4">
                                        <img id="image" src="./images/feature-image1.png" alt="" width="100%" height="auto"/>
                                    </Col>
                                    <Col  xs="8" md="8">
                                        <div className="content">
                                           <div className="header">
                                            Faster Speeds
                                           </div>
                                           <div  className="detail">
                                              Download movies, games and contents in seconds
                                           </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="homepage-features">
                                <Row >
                                     <Col xs="4" md="4">
                                        <img id="image" src="./images/feature-image2.png" alt="" width="100%" height="auto"/>
                                    </Col>
                                    <Col  xs="8" md="8">
                                        <div className="content">
                                            <div className="header">
                                             Lower Latency
                                            </div>
                                            <div className="detail">
                                                Quick response between devices with less loading and waiting
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="homepage-features">
                                  <Row>
                                    <Col xs="4" md="4">
                                        <img id="image" src="./images/feature-image3.png" alt="" width="100%" height="auto"/>
                                    </Col>
                                    <Col  xs="8" md="8">
                                         <div className="content">
                                             <div className="header">
                                                Better Bandwidth
                                             </div>
                                            <div  className="detail">
                                                Connect more devices without reduction in speed and quality
                                            </div>
                                        </div>
                                    </Col>
                                </Row> 
                            </div>
                        </Col>
                    </Row>
                    {!isFullScreen? <div className="bottom-spacing"></div> : ""}
                </React.Fragment>: ""
                }
                {
                 btnGroupChildIndex === 1 ||  btnGroupChildIndex === 2 ?
                 <React.Fragment>
                    <Row>
                        <Col xs="12"  md="12"  className="text-center">
                            <div className="coming-soon">
                                <span className="coming-soon-text">
                                     Coming Soon
                                </span>
                            </div> 
                        </Col>
                    </Row>
                    {!isFullScreen? <div className="bottom-spacing"></div> : ""}
                 </React.Fragment> : ""
                }
                <Row>
                    <Col xs="12" md="12" >
                        <a target="_blank"  rel="noopener noreferrer"  id="btnSignUp" href="http://www.singtel.com"
                         className="btn signup-button">
                            Sign up now
                        </a>
                    </Col>
                </Row>
            </div>
            </ScrollContainer >
            <Footer data={props.location.pathname}/>
            </div>
            
        </React.Fragment>
    );
  }

  export default Home;