import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
function Footer(data) {
  const path = data.data;
    return (
        <div className="footer" >
        <Row>
          <Col xs="4" md="4">
            <Link id="link1" to={{pathname: '/home'}} className="footer-icon" >
                <img id="image1" width="31%" height="auto" src= {path == "/home" ? "./images/nav1_selected.png" : "./images/nav1.png"}  alt=""/>
            </Link>
           
          </Col>
          <Col xs="4" md="4">
            <Link id="link2" to={{pathname: '/detail'}} className="footer-icon" >
               <img id="image2" width="50%" height="auto"  src= {path == "/detail" ? "./images/nav2_selected.png" : "./images/nav2.png" }  alt=""/>
            </Link>
          </Col>
          <Col xs="4" md="4">
            <a id="link3" target="_blank"  rel="noopener noreferrer"   href="https://www.singtel.com/personal/products-services/mobile/5g/roi-5g.html" className="footer-icon" 
              >
                <img id="image3" width="43%" height="auto"  src="./images/nav3.png"  alt=""/>
            </a>
          </Col>
        </Row>
    </div>
    );
}

export default Footer;