import React from "react";
import { Switch, Route } from "react-router-dom";
import Landing from "../components/landing";
import Home from "../components/home";
import Detail from "../components/detail";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Landing} />
      <Route path="/home" component={Home} />
      <Route path="/detail" component={Detail} />
    </Switch>
  );
}
