import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Slider from "react-slick";
import Footer from '../footer';
import { isMobileOnly} from "react-device-detect";
import ScrollContainer from 'react-indiana-drag-scroll';
import ReactPlayer from 'react-player';
import ReadMore from './ReadMore';

function Detail(props) {
  const initialState = typeof(props.location.state) !== 'undefined' ?  props.location.state.id : 0;
    const [isSliding, setIsSliding] = useState(false);
    const [videoPlayerId, setVideoPlayerId] = useState("");
    const [contentIndex, setContentIndex] = useState(initialState);
    const getWrapperWidth = () => {
  
    }

    const [isFullScreen, setFullScreen] = useState(false);

    const isPwa = () => {
      return ["fullscreen", "standalone", "minimal-ui"].some(
          (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
      );
   }

   useEffect(() => {
      if (isPwa()) {
          setFullScreen(true);
      }
   });


    const handleVideoClose = (e) => {
      e.preventDefault();
      var elem = document.getElementById(videoPlayerId);
      if(elem){
        hideVideo(elem);
      }
    }

    const handleFullScreenChange = () => {
      if (!document.fullscreenElement){
        var elem = document.getElementById(videoPlayerId);
        hideVideo(elem);
      }  
    }

    const showHideCloseButton = (prop) =>{
      var elem1 = document.getElementById("btnVideoClose");
      if(elem1){
        if(prop){
          elem1.style.display = "block";
        }
        else{
          elem1.style.display = "none";
        }
      } 
    }

    const hideVideo = (elem) =>{
      if (elem && elem.style.display === "block") { 
        setVideoPlayerId("");
        elem.style.display = "none";
        if (!isMobileOnly) {
          elem.classList.remove("desktopFullScreenVideo");
        }
        else{
          if(elem.classList.contains("mobileFullScreenVideo")){
            elem.classList.remove("mobileFullScreenVideo");  
          }
        }
        showHideCloseButton(false);
      }
    }

    const _onReady = (event) => {
      // access to player in all event handlers via event.target
      //event.target.pauseVideo();
    }

    useEffect(() => {
      document.addEventListener("fullscreenchange", handleFullScreenChange);
      document.addEventListener("mozfullscreenchange", handleFullScreenChange);
      document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
      document.addEventListener("msfullscreenchange", handleFullScreenChange);
    });

    const playVideo = (event) =>  {
      if(isSliding) return;

      var videoId = event.target.getAttribute('data-video-id');
      if(videoId){
          setVideoPlayerId(videoId);
        
          var elem = document.getElementById(videoId);  
          if (elem.style.display === "none") {
           elem.style.display = "block";
          } 
          
          if (isMobileOnly) {  
            if(elem){
              var videoTag = elem.children[0];
              if(videoTag){
                videoTag.addEventListener("webkitendfullscreen", function(){
                  hideVideo(elem);
                }, false);

                if (videoTag.requestFullScreen) {
                  videoTag.requestFullScreen();
                } else if (videoTag.mozRequestFullScreen) { 
                  videoTag.mozRequestFullScreen();
                } else if (videoTag.webkitEnterFullScreen) {
                  videoTag.webkitEnterFullScreen();
                } else if (videoTag.msRequestFullScreen) { 
                  videoTag.msRequestFullScreen();
                }   
                else {   
                  elem.classList.add("mobileFullScreenView");   
                  showHideCloseButton(true);
                }
              }
            }
            var type = event.target.getAttribute('data-type');
            if(type == "image"){
              showHideCloseButton(true);
            }
          }
          else{
            elem.classList.add("desktopFullScreenVideo");
            showHideCloseButton(true);
          }
        }
     };
  
    const settings = isMobileOnly ? {
        centerMode: true,
        centerPadding: '60px',
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide : initialState,
        dots: true,
        beforeChange: () => setIsSliding(true),
        afterChange: next => {
            setIsSliding(false);
            setContentIndex(next);
        },
      } : {
        centerMode: true,
        centerPadding: '60px',
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide : initialState,
        dots: true,
        beforeChange: () => setIsSliding(true),
        afterChange: next => {
            setIsSliding(false);
            setContentIndex(next);
        },
        variableWidth: true
      };

    return (
      <React.Fragment>
      <ScrollContainer className="detailpage-container" horizontal={false}>
        <Container>
          <div className="detailpage-header">
              <Row>
                  <Col xs="1" md="1">
                    <Link to={{pathname : "/home"}}><img  src="./images/Pin-Left.png" className="left"/></Link>
                  </Col>
                  <Col xs="10" md="10"><span className="detailpage-demo-room-header">5G Demo Room</span></Col>
              </Row>
          </div>
          <Row>
            <Col>
            </Col>
          </Row>
          <Slider {...settings} id="detail-page-slider">
            <div>
              <img
                className="d-block w-100 slide-item-image"
                src="./images/lg-thumb1.png"
                alt="Third slide"
                width="100%"
                height="auto"
                data-type="video"
              />
              <img src="./images/playbutton.png" 
                width="30%"
                height="auto"
                alt=""
                className="centered"
                data-video-id="slide1Video" 
                onClick={playVideo}
              />
            </div>
            <div>
              <img
                  className="d-block w-100 slide-item-image"
                  src="./images/lg-thumb2.png"
                  alt="Third slide"
                  width="100%"
                  height="auto"
                  data-video-id="slide2Image"
                  data-type="image"
                  onClick={playVideo}
                />
            </div>
            <div>
              <img
                  className="d-block w-100 slide-item-image"
                  src="./images/lg-thumb3.png"
                  alt="Third slide"
                  width="100%"
                  height="auto"
                  data-type="video"
                />
              <img src="./images/playbutton.png" 
                width="30%"
                height="auto"
                alt=""
                className="centered"
                data-video-id="slide3Video" 
                onClick={playVideo}
              />
            </div>
            <div>
              <img
                  className="d-block w-100 slide-item-image"
                  src="./images/lg-thumb4-1.png"
                  alt="Third slide"
                  width="100%"
                  height="auto"
                  data-type="video"
                />
              <img src="./images/playbutton.png" 
                width="30%"
                height="auto"
                alt=""
                className="centered"
                data-video-id="slide4Video" 
                onClick={playVideo}
              />
            </div>
            <div>
              <img
                  className="d-block w-100 slide-item-image"
                  src="./images/lg-thumb5.png"
                  alt="Third slide"
                  width="100%"
                  height="auto"
                  data-type="video"
                />
              <img src="./images/playbutton.png" 
                width="30%"
                height="auto"
                alt=""
                className="centered"
                data-video-id="slide5Video" 
                onClick={playVideo}
              />
            </div>
            <div>
              <img
                    className="d-block w-100 slide-item-image"
                    src="./images/lg-thumb6.png"
                    alt="Third slide"
                    width="100%"
                    height="auto"
                    data-type="video"
                  />
                <img src="./images/playbutton.png" 
                  width="30%"
                  height="auto"
                  alt=""
                  className="centered"
                  data-video-id="slide6Video" 
                  onClick={playVideo}
                />
            </div>
            <div>
              <img
                  className="d-block w-100 slide-item-image"
                  src="./images/lg-thumb7.png"
                  alt="Third slide"
                  width="100%"
                  height="auto"
                  data-type="video"
                />
              <img src="./images/playbutton.png" 
                width="30%"
                height="auto"
                alt=""
                className="centered"
                data-video-id="slide7Video" 
                onClick={playVideo}
              />
            </div>
          </Slider>
          {contentIndex == "0" ?
          <div id="slide1Content" className="slideContent">
            <Row>
                <Col><div className="header">AR MURAL</div></Col>
            </Row>
              <Row>
                <Col>
                <div>
                <p>
                <ReadMore 
                    text={
                    `Singtel 5G will bring about a whole new world of connected experiences. \r\nThis artwork is an interpretation of modern-day Singapore. Use Augmented Realty (AR) to get a glimpse of how 5G technology will transform the Retail, Travel and Entertainment experience. Shop with Robo-Assistants who remember your preferences, shoot across town in a Volocopter and take your games to the next level with immersive VR.`} 
                    lineHeight={1.2}
                    showLessButton={true}
                    onContentChange={getWrapperWidth}
                  />
                </p>
                </div>
                </Col>
              </Row>
            <Row>
                <Col><p className="unboxed-text">Experience it live at UNBOXED@Tampines.</p></Col>
            </Row>
            <Row>
                <Col>
                <a id="btnExperience" href="https://l.ead.me/unboxed5g"
                   target="_blank"  rel="noopener noreferrer"   className="btn detailpage-experience-button">
                       <img src="./images/cube.png" className="image-cube"></img>
                      <span className="experience-text">Experience now</span> 
                </a>
                </Col>
             
                <br/><br/>
            </Row>
           
          </div>
          : ""}
          {contentIndex == "1" ?
          <div id="slide2Content" className="slideContent">
            <Row>
                <Col><div className="header">VR EVENTS</div></Col>
            </Row>
            <Row>
                <Col>
                <div>
                <p>
                <ReadMore 
                    text={
                    `Experience live events like never before all available at your fingertips! Watch them come alive in immersive 360° video, by turning your device or dragging with your finger. With Virtual Reality (VR), you can stand on stage at sold out shows and more through your VR headset or mobile phone. Have complete control to choose what you see and where you stand in a venue by selecting different viewpoints.`} 
                    lineHeight={1.2}
                    showLessButton={true}
                    onContentChange={getWrapperWidth}
                  />
                </p>
                </div>
                </Col>
            </Row>
           </div> : ""}
          {contentIndex == "2" ?
          <div id="slide3Content" className="slideContent">
            <Row>
                <Col><div className="header">AR BOOK</div></Col>
            </Row>
            <Row>
                <Col>
                <div>
                <p>
                <ReadMore 
                    text={
                    `Singtel has partnered with Bookful, an interactive Augmented Reality library for children, bringing books to life by integrating computer-generated animations into your existing surroundings using AR and 3D. Bookful includes games like magic paint, mini golf and dance. The app merges the digital and physical worlds to create an interactive reading experience to develop in children a passion for books.`} 
                    lineHeight={1.2}
                    showLessButton={true}
                    onContentChange={getWrapperWidth}
                  />
                </p>
                </div>
                </Col>
            </Row>
          </div>: ""}
          {contentIndex == "3" ?
          <div id="slide4Content" className="slideContent">
            <Row>
                <Col><div className="header">MIXED REALITY</div></Col>
            </Row>
            <Row>
                <Col>
                <div>
                <p>
                <ReadMore 
                    text={
                    `Mixed Reality is experienced using special headsets or glasses that allow users to interact with virtual objects placed in real world settings and in real time.  Hold remote holographic meetings, collaborate on virtual whiteboard, 3D objects and virtual classroom  with enhanced 3D spaces. Enjoy immersive 3D computer games using real-world surroundings in the game environment.`} 
                    lineHeight={1.2}
                    showLessButton={true}
                    onContentChange={getWrapperWidth}
                  />
                </p>
                </div>
                </Col>
            </Row>
          </div>: ""}
          {contentIndex == "4" ?
          <div id="slide5Content" className="slideContent">
            <Row>
                <Col><div className="header">CLOUD GAMING</div></Col>
            </Row>
            <Row>
                <Col>
                <div>
                <p>
                <ReadMore 
                    text={
                    `Cloud gaming brings PC and console level performance to you anywhere. By processing games on cloud servers and sending only the video stream to your device through the internet, Cloud Gaming transforms almost any device with a fast-enough connection into a highend gaming machine. 5G's extremely low latency further enhances this experience by rendering input lag unnoticeable, as if the game was running on your 5G device itself.`} 
                    lineHeight={1.2}
                    showLessButton={true}
                    onContentChange={getWrapperWidth}
                  />
                </p>
                </div>
                </Col>
            </Row>
          </div>: ""}
          {contentIndex == "5" ?
          <div id="slide6Content" className="slideContent">
            <Row>
                <Col><div className="header">STELLA 5G VIRTUAL ASSISTANT</div></Col>
            </Row>
            <Row>
                <Col>
                <div>
                <p>
                <ReadMore 
                    text={
                    `Stella is an intelligent voice-assisted humanized bot that will serve customers 24/7 through self-serve kiosks at UNBOXED and Singtel Shops. With faster 5G speeds, instant data communication between backend and frontend systems allows real-time, seamless conversations with customers. Stella can identify customers through Facial Recognition, and offer up personalized recommendations of product and services based on customer's past interactions. Stella can also help customers with Prepaid and Dash top-ups. \r\n\r\nFrom October, Stella can serve customers on end-to-end sales transactions like recontracting of plans, beyond current shop or video chat operating hours.`} 
                   lineHeight={1.2}
                    showLessButton={true}
                    onContentChange={getWrapperWidth}
                  />
                </p>
                </div>
                </Col>
            </Row>
          </div>: ""}
          {contentIndex == "6" ?
          <div id="slide7Content" className="slideContent">
            <Row>
                <Col><div className="header">STANLEY 5G ROBOT</div></Col>
            </Row>
            <Row>
                <Col>
                <div>
                <p>
                <ReadMore 
                    text={
                    `Stanley, our latest smart robot ambassador for UNBOXED, is available 24/7 to help customers navigate and assist them in their shop journey. He is integrated with our intelligent security system, Sentinel by NCS to detect safety breaches such as customers who are running a temperature, not wearing their masks or when safe distancing is not enforced. Using 5G, our Sentinel system will trigger Stanley in real-time to alert customers to follow the safety measures. \r\n\r\nFrom September, Stanley will also be equipped with disinfecting capabilities. `} 
                   lineHeight={1.2}
                    showLessButton={true}
                    onContentChange={getWrapperWidth}
                  />
                </p>
                </div>
                </Col>
            </Row>
          </div>: ""}
          <Row>
            <Col xs="12" md="12" >
               <a target="_blank" id="btnSignUp" rel="noopener noreferrer"  href="http://www.singtel.com"
               className="btn signup-button">
                Sign up for 5G
              </a>
            </Col>
          </Row>
       </Container>
       {!isFullScreen? <div className="bottom-spacing"></div> : ""}
    </ScrollContainer>
  
    <Footer  data={props.location.pathname}/>

    <a href="#" id="btnVideoClose" onClick={handleVideoClose} style={{display: "none"}} className="close"></a>
    <ReactPlayer
        id="slide1Video"
        url= "./videos/video1.mp4"
        config={{
          file: { 
            attributes: { 
              preload: 'none' 
            } 
          } 
        }}
        playing = { videoPlayerId == "slide1Video" ? true : false}
        style = {{display : "none"}}
        controls = {true}
    />
    <div id="slide2Image" style = {{display : "none"}}> 
      <img className="robot-image" src="./images/lg-thumb2.png" ></img>
    </div>
     <ReactPlayer
        id="slide3Video"
        url= "./videos/video3.mp4"
        config={{
          file: { 
            attributes: { 
              preload: 'none' 
            } 
          } 
        }}
        playing = { videoPlayerId == "slide3Video" ? true : false}
        style = {{display : "none"}}
        controls = {true}
    />
     <ReactPlayer
        id="slide4Video"
        url= "./videos/video4.mp4"
        config={{
          file: { 
            attributes: { 
              preload: 'none' 
            } 
          } 
        }}
        playing = { videoPlayerId == "slide4Video" ? true : false}
        style = {{display : "none"}}
        controls = {true}
    />
     <ReactPlayer
        id="slide5Video"
        url= "./videos/video5.mp4"
        config={{
          file: { 
            attributes: { 
              preload: 'none' 
            } 
          } 
        }}
        playing = { videoPlayerId == "slide5Video" ? true : false}
        style = {{display : "none"}}
        controls = {true}
    />
     <ReactPlayer
        id="slide6Video"
        url= "./videos/video6.mp4"
        config={{
          file: { 
            attributes: { 
              preload: 'none' 
            } 
          } 
        }}
        playing = { videoPlayerId == "slide6Video" ? true : false}
        style = {{display : "none"}}
        controls = {true}
    />
    <ReactPlayer
        id="slide7Video"
        url= "./videos/video7.mp4"
        config={{
          file: { 
            attributes: { 
              preload: 'none' 
            } 
          } 
        }}
        playing = { videoPlayerId == "slide7Video" ? true : false}
        style = {{display : "none"}}
        controls = {true}
    />
    </React.Fragment>
    );
  }
  export default Detail;