import React from 'react';
import { HashRouter } from 'react-router-dom';
import history from './services/history';
import Routes from './routes';
import {getBasename, getCurrentTime} from "./utils";

function App() {
  return (
    <HashRouter history={history}>
      <div  className="smartphone-container">
        <div className="smartphone">
            <div className="smartphone-time">
              {getCurrentTime()}
            </div>
            <div className="smartphone-icons">
              <img src="./images/mobile_signal.png"   alt="" className="icon" />
              <img src="./images/wifi.png"   alt=""  className="icon" />
              <img src="./images/battery.png"   alt=""  className="icon" />
            </div>
            <Routes />
        </div>
        <div className="instruction"><br/>For best experience, please view this site from a mobile.</div>
      </div>
    </HashRouter>
  );
}
export default App;
