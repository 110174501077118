export const getBasename = path => {
    try{
       return path.substr(0, path.lastIndexOf('/'));
    }
    catch(err)  { return '';}
}

export const getCurrentTime = () =>{
    var date = new Date(); 
    var hours = date.getHours(); 
    var minutes = date.getMinutes(); 
    
    // Check whether AM or PM 
    var newformat = hours >= 12 ? 'PM' : 'AM';  
    
    // Find current hour in AM-PM Format 
    hours = hours % 12;  
    
    // To display "0" as "12" 
    hours = hours ? hours : 12;  
    minutes = minutes < 10 ? '0' + minutes : minutes; 

    return hours + ':' + minutes + ' ' + newformat;
}