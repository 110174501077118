import React, {Component} from 'react';
import './read-more.css';

const ELLIPSES = '…';
const SHOW_LESS_TEXT = 'Hide';
const SHOW_MORE_TEXT = 'Read more';

export default class ReadMore extends Component {

  static defaultProps = {
    numberOfLines: 2,
    lineHeight: 1,
    readMoreWordLimit: 26,
    showLessButton: false
  }

  state = { 
    showingAll: false
  }

  componentDidMount() {
    this.props.onContentChange();
  }
  
  toggleReadMore = () => {
    this.setState({
      showingAll: !this.state.showingAll
    });
  }

  _getReadMoreParts = ({text, numberOfLines, readMoreWordLimit}) => {
      let teaserText;
      let remainingText;
      let remainingWordsArray = [];

      if (text) {
          const teaserWordsArray = text.split(' ');
          while (teaserWordsArray.length > readMoreWordLimit ) {
              remainingWordsArray.unshift(teaserWordsArray.pop());
          }
          teaserText = teaserWordsArray.join(' ');

          if (remainingWordsArray.length > 0) {
              remainingText = remainingWordsArray.join(' ');
          }
      }

      return {
          teaserText,
          remainingText
      };
  };

  getText = ({showingAll, text, readMoreWordLimit, numberOfLines}) => {
    let {
      teaserText,
      remainingText
     } = this._getReadMoreParts({text, numberOfLines, readMoreWordLimit});

    if (!showingAll && text.length > readMoreWordLimit) {
      return (
        <div>
          {teaserText.replace(/\s*$/, "")}
          <span className="read-more__text--remaining read-more__text--hide">
            {remainingText}
          </span>{ELLIPSES}
        </div>
      );
    }

    return (
      <div>
        {teaserText}
        <div className="read-more__text--remaining read-more__text--show">
          {remainingText}
        </div>
      </div>
    );
  }

  getActionButton = ({showingAll, showLessButton}) => {
    if (showingAll && !showLessButton) {
      return
    }

    let buttonText = showingAll ? SHOW_LESS_TEXT : SHOW_MORE_TEXT;

    return (
      <a 
        onClick={this.toggleReadMore}
        className="more-text" 
        >
          {buttonText}
      </a>
    );
  }

  render () {
    let {
      text, 
      readMoreWordLimit, 
      showLessButton,
      onContentChange,
      numberOfLines,
      lineHeight
    } = this.props;
    /*
    console.log(numberOfLines, lineHeight, numberOfLines * lineHeight);
    let maxHeight = numberOfLines * lineHeight;
    let style = {
      lineHeight,
      maxHeight: `${maxHeight}em`
    }*/
    let {showingAll} = this.state;
    let textToDisplay = this.getText({showingAll, text, readMoreWordLimit, numberOfLines})
                         ;
    let actionButton = this.getActionButton({showingAll, showLessButton});

    return (
      <div className="read-more">
        {textToDisplay} 
        {actionButton}
      </div>
    )
  };

};
